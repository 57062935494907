window.App ||= {}
class App.WorkOrders extends App.Base

  index: =>
    window.scanContext = 'WorkOrder'
    Utility.Sidebar.refreshSideBar()
    Utility.Datatables.workOrders()
    Utility.FormActions.prepareIndexTable()
    Utility.Drawer.toggleDrawers()
    Utility.FormActions.prepareLocationCategoryAjaxLinks()
    Utility.HelpPanel.prepare()
    App.WorkOrders.prepareBulkOperations()
    $('.scan-lookup').click ->
      if (typeof window.JsInterface != 'undefined')
        JsInterface.scanInternalBarcode()
      else
        ScanInterface.scanInternalBarcode()

  new: =>
    Utility.SelectizeFields.prepare()
    Utility.Datepicker.prepare()
    Utility.FormActions.removeParentByClass()
    this.prepareTaskSelectorToggle()
    App.CustomFields.showOrHideAddLink()
    this.prepareEquipmentIdSelect()
    Utility.FlyInPanel.prepare()

  show: =>
    window.scanContext = 'WorkOrder'
    Utility.SelectizeFields.prepare()
    this.prepareShowAjaxLinks()
    this.preparePartsLaborEditor()
    Utility.Datepicker.prepare()
    Utility.Collapsable.prepare()
    Utility.NextPrevious.prepare('work_orders')
    App.WorkOrders.prepareWorkOrderForm()
    App.Attachments.fileUpload()
    Utility.Datatables.workOrderAttachments()
    Utility.FormActions.prepareIndexTable()
    this.prepareEquipmentStatusChange()
    this.prepareSortable()
    $("html, body").scrollTop(0)

  edit: =>
    Utility.SelectizeFields.prepare()
    Utility.Datepicker.prepare()
    App.WorkOrders.prepareWorkOrderForm()
    this.prepareShowAjaxLinks()
    App.CustomFields.showOrHideAddLink()
    Utility.FlyInPanel.prepare()

  new_task: (allowCreate) =>
    $('select#inline_tasks').selectize
      create: allowCreate
      createOnBlur: allowCreate
      plugins: ['remove_button']
      delimiter: null
      sortField: 'text'
    $('select#inline_tasks')[0].selectize.on 'change', (array) ->
      if array?.length == 1 || (array?.length == 2 && array.pop() == 'Repair')
        $('#task_submit_and_edit').show()
      else
        $('#task_submit_and_edit').hide()
    $("select#inline_tasks").siblings('.selectize-control').find('input').click()
    $("#wo-add-task").hide()
    $("#cancel-task").click ->
      $("#form-add-task").hide()
      $("#wo-add-task").show()
    $("input.other_task").hide()
    $("input.task-type-other").click ->
      $("input.other_task").show()
      $("span.pm-repairs-select").hide()
      $("input#task_submit_and_edit").hide()
    $("input.task-type-pm-repair").click ->
      $("input.other_task").hide()
      $("span.pm-repairs-select").show()
      $("input#task_submit_and_edit").show()

  @prepareAddInlineTask = ->
    $(".inline-task:last input.other_task").hide()
    $("input.task-type-other").click ->
      $("input.other_task[data-task-id='" + $(@).data('task-id') + "'").show()
      $("span.pm-repairs-select[data-task-id='" + $(@).data('task-id') + "']").hide()
      $("span.pm-repairs-select[data-task-id='" + $(@).data('task-id') + "'] > #inline_tasks")[0].selectize.setValue('');
    $("input.task-type-pm-repair").click ->
      $("input.other_task[data-task-id='" + $(@).data('task-id') + "'").hide()
      $("input.other_task[data-task-id='" + $(@).data('task-id') + "'").val('')
      $("span.pm-repairs-select[data-task-id='" + $(@).data('task-id') + "']").show()

  @scan = (scanKey) ->
    $.ajax
      url: "/work_orders/scan"
      type: 'GET'
      data: {
        scan_key: Utility.Scanner.trimScanKey(scanKey)
      }
      cache: false
      dataType: 'script'

  @scanTaskPart = (assignedTaskId, scanKey) ->
    $.ajax
      url: "/assigned_tasks/" + assignedTaskId + "/task_parts/new"
      type: 'GET'
      data: {
        scan_key: Utility.Scanner.trimScanKey(scanKey)
      }
      cache: false
      dataType: 'script'

  @calculateTotalCost = ->
    if $("input.unit-cost").length > 0
      unitCost = parseFloat($("input.unit-cost").val().replace(/[^0-9.]/g, ''))
      quantity = parseFloat($("input.quantity").val().replace(/[^0-9.]/g, ''))
      unitCost = 0 unless unitCost > 0
      quantity = 0 unless quantity > 0
      calculatedTotalCost = unitCost * quantity
      if calculatedTotalCost > 0
        $("#itemize-or-total-cost").hide()
        $("#calculated-total-cost").val(parseFloat(Math.round(calculatedTotalCost * 100) / 100).toFixed(2))
        $("#total-cost-entry").hide()
      else
        $("#itemize-or-total-cost").show();
        $("#calculated-total-cost").val("")

  @prepareCreditAmountEditor = ->
    $(document).off('keyup', ".enforce-positive").on 'keyup', ".enforce-positive", ->
      if parseInt($(@).val()) < 0
        $(@).val($(@).val() * -1)

  prepareEquipmentStatusChange: =>
    $("#equipment_status").on 'change', ->
      $("form.equipment_status").submit()

  @prepareBulkOperations = ->
    Utility.BulkOperations.prepare()
    $("#bulk-print-work-orders").click ->
      work_order_ids = $("input[name='select_item[]']:checked").map ->
          return this.value
        .get()
      window.open("/work_orders/bulk_print?work_order_ids=" + work_order_ids, '_blank')
    $("#bulk-download-work-order-attachments").click ->
      work_order_ids = $("input[name='select_item[]']:checked").map ->
          return this.value
        .get()
      window.open("/work_orders/bulk_download?work_order_ids=" + work_order_ids, '_blank')
    $("#bulk-issue-invoices").off('click').click ->
      work_order_ids = $("input[name='select_item[]']:checked").map ->
          return this.value
        .get()
      $.get '/work_orders/bulk_issue_invoices?work_order_ids=' + work_order_ids, null, null, 'script'



  prepareEquipmentIdSelect: =>
    $("#work_order_equipment_id").change ->
      if parseInt($(@).val()) > 0
        $.get '/work_orders/new' + '?equipment_id=' + $(@).val(), null, null, 'script'
    if parseInt($("#work_order_equipment_id").val()) > 0
      Utility.EquipmentRequired.enable()
      $('#work_order_assignee_type_and_ids')[0].selectize.focus()
    else
      Utility.EquipmentRequired.disable()
      $('#work_order_equipment_id')[0].selectize.focus()

  prepareTaskSelectorToggle: =>
    $("#check_all_checkbox").change ->
      $("[name='work_order[task_ids][]']").prop('checked', @.checked)

  preparePartsLaborEditor: =>
    $(document).off('keyup', ".quantity, .unit-cost").on 'keyup', ".quantity, .unit-cost", ->
      App.WorkOrders.calculateTotalCost()

  prepareShowAjaxLinks: =>
    $(document).off('change', "#task_part_inventory_id").on 'change', "#task_part_inventory_id", ->
      id = $(@).val()
      task_id = $(@).attr('data-assigned-task-id')
      if parseInt(id) > 0
        $.ajax
          url: "/assigned_tasks/"+task_id+"/task_parts/get_part_unit_cost?part_id="+id
          data: null
          dataType: 'script'

    $(document).off('change', "#task_technician_technician_type_and_id").on 'change', "#task_technician_technician_type_and_id", ->
      id = $(@).val()
      task_id = $(@).attr('data-assigned-task-id')
      $.ajax
        url: "/assigned_tasks/"+task_id+"/task_technicians/get_labor_rate?technician_type_and_id="+ id
        data: null
        dataType: 'script'

  @prepareWorkOrderForm = ->
    $(document).off('click', "a#submit-close-form").on 'click', "a#submit-close-form", ->
      $("#new_work_order_completion").submit()

    $(document).off('click', '.scan-part-link').on 'click', '.scan-part-link', ->
      if (typeof window.JsInterface != 'undefined')
        JsInterface.scanTaskPart($(@).data('assigned-task-id'))
      else
        ScanInterface.scanTaskPart($(@).data('assigned-task-id'))

    $("[data-role='work-order-select'] tr").click (e) ->
       unless e.target.nodeName is 'INPUT' or e.target.nodeName is 'LABEL'
        $(':checkbox', @).trigger 'click'

    $("#wo-add-task").click ->
      $.getScript("/work_orders/" + $(@).data('work-order-id') + "/assigned_tasks/new")

    $("[data-click='cancel-edit-add']").click ->

  prepareSortable: =>
    el = $("#work-order-task-list")[0]
    sortable = Sortable.create(el,
      {
        handle: ".handle"
        scroll: true
        onEnd: (e) ->
          if e.oldIndex != e.newIndex
            console.log e
            $.ajax
              url: "/work_orders/" + $("#work-order-task-list").data("work-order-id") + "/reorder"
              type: 'put'
              data: {tasks:{ordered_field_array: $.map($("#work-order-task-list li"), (e) -> $(e).data("name"))}}
              cache: false
              dataType: 'script'
      }
    )

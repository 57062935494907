window.scanContext = 'Ignore'
window.App ||= {}

# Shim for webpack exports
Object.assign(Sortable, Sortable.Sortable)

class App.Base

  constructor: ->
    # For use with fly-in panel to weed out underlying elements that otherwise might
    # be found by JQuery
    $.expr[':'].focusable = (obj) ->
      $this = $(obj)
      $this.css('pointer-events') != 'none'

    $.extend($.fn.dataTable.defaults, {
      jQueryUI: true
      displayLength: 25
      autoWidth: false
      language:
        emptyTable: 'No matching records found'
      lengthChange: false
      preDrawCallback: (settings) ->
        table_id = $(@).attr('id')
        if !$(@).hasClass('initialized') && localStorage[table_id + '-remember_search'] == 'true'
          this.api().table().search(localStorage[table_id + '-search'])
      initComplete: (settings, json) ->
        table_id = $(@).attr('id')
        $(@).addClass('initialized')
        rs = $('input.remember_search[data-table-id="' + table_id + '"]')

        if localStorage[table_id + '-remember_search'] == 'true'
          rs.prop('checked', true)
          rs.siblings('i.fa-lock').show()
          rs.siblings('i.fa-unlock').hide()
          rs.parent().siblings('#clear-search').show()
          if localStorage[table_id+ '-search'] != ""
            $('input.text_search[data-table-id="'+table_id+'"]').val(localStorage[table_id + '-search'])
          #this.api().table().search(localStorage[table_id + '-search']).draw();
        else
          rs.siblings('i.fa-lock').hide()
          rs.siblings('i.fa-unlock').hide()
          rs.parent().siblings('#clear-search').hide()

        $(this.api().table().node()).on 'click', 'a.show-fly-in', (event) ->
          Utility.FlyInPanel.show(window.location.href)
    })

    resize_timeout = undefined
    confirm_form_change = 'You have not saved your changes, do you wish to leave this form without saving?'

    window.onTurnstileComplete = ->
      $('input[type="submit"]').show()

    $(window).on 'resize orientationchange', ->
      clearTimeout resize_timeout
      resize_timeout = setTimeout((->
        $(window).trigger 'resized'
      ), 250)

    if (window.jQuery) then @setClearEventHandlers() # clearing application event handlers only possible with jQuery
    $(window).load ->
      $("#page-loader").hide()
      $(".flash").each (index, element) =>
        msg = $(element).attr('data-message')
        if msg != null
          $.jGrowl(msg, {position: 'center', theme: $(element).attr('data-theme'), life: 5000})

    # Empty unload window handler necessary to break bfcache in Firefox and Safari
    # http://madhatted.com/2013/6/16/you-do-not-understand-browser-history
    $(window).unload ->

    $(document).on 'ajaxStart', ->
      $('body').css('cursor', 'wait')
      $('footer').removeClass('tw-hidden')

    $(document).on 'ajaxStop', ->
      $('body').css('cursor', 'auto')

    document.addEventListener "wheel", (e) ->
      if (document.activeElement.type == "number")
        document.activeElement.blur();

    $(document).on 'ready', ->
      Utility.Clipboards.prepare()
      Utility.Drawer.prepare()
      Utility.Dropup.prepare()
      Utility.Sidebar.prepare()
      Utility.Datepicker.prepare()
      Utility.Toggle.prepare()
      Utility.SelectizeFields.prepare()
      Utility.Scanner.prepare()
      Utility.LogoutTimer.start()
      Utility.ProductFruits.prepare()
      App.Equipments.prepareUploads()
      Utility.TrixUploads.prepare()
      Utility.HelpPanel.prepare()
      Utility.HelpscoutBeacon.prepare()

      Highcharts.setOptions
        lang:
          thousandsSep: ','

      # Disable jquery timestamp parameters & maintain the fly-in render target header
      $.ajaxSetup
        cache: true
        beforeSend: (xhr, _settings) ->
          if Utility.FlyInPanel.flyInActive
            xhr.setRequestHeader('X-Render-Target', 'fly-in')

      # Need to set history state for page load with our parameter
      history.replaceState({fmp: true}, "", window.location.href)

      # Only follow popstate if we initiated it -- prevents double loading in Safari
      $(window).bind "popstate", (event) ->
        return unless event.originalEvent.state && event.originalEvent.state.fmp == true
        $.getScript(location.href)

      $(document).ajaxComplete (event, xhr, settings) ->
        ReactRailsUJS.mountComponents()
        if xhr.getResponseHeader('X-ClearLocalStorage') == 'true'
          localStorage.clear()
          location.reload(true)

        msg = xhr.getResponseHeader("X-Message")
        theme = xhr.getResponseHeader("X-Message-Type")

        if msg != null
          $.jGrowl(msg, {position: 'center', theme: theme})

        if xhr.getResponseHeader("X-Request-Method") == 'GET' && xhr.getResponseHeader("X-Skip-Pushstate") != 'true' && xhr.getResponseHeader("X-Request-Path").indexOf(".json") == -1
          Utility.MainMenu.setMenu(xhr.getResponseHeader("X-Request-Path"))

          if (window.location.pathname != xhr.getResponseHeader("X-Request-Path") || xhr.getResponseHeader("X-Request-Path").indexOf("reports/") != -1)
            if xhr.getResponseHeader("X-Ajax-Anchor") == null
              # Strip off anchor tab if ajax anchor
              history.pushState({fmp: true}, "", xhr.getResponseHeader("X-Request-Fullpath"))
            else
              history.pushState({fmp: true}, "", xhr.getResponseHeader("X-Request-Path"))

          scrollingIsPrevented = $('body').hasClass('prevent-scrolling')
          $('body').removeClass()
          $('body').addClass(xhr.getResponseHeader("X-Body-Class")).addClass('app-body')
          # Reapply the scroll-preventing class if it was there prior to clearing the body classes
          $('body').addClass('prevent-scrolling') if scrollingIsPrevented

        if xhr.getResponseHeader("X-Ajax-Anchor") != null
          window.location.hash = xhr.getResponseHeader("X-Ajax-Anchor")
          Utility.AccordionTab.prepare()

        # Close the fly-in panel if it's open with a form posted
        if settings.type != 'GET' && Utility.FlyInPanel.flyInActive
          # But keep it open if there are validation errors or its marked to stay open
          persistRenderTarget = xhr.getResponseHeader("X-Persist-Render-Target")
          if Utility.FlyInPanel.shouldClose(xhr.getResponseHeader("X-Message-Type")) && !persistRenderTarget
            if Utility.FlyInPanel.reloadAfterClose
              Utility.FlyInPanel.hide(false, false, true)
            else
              Utility.FlyInPanel.hide(true, false, true)

      $(document).on 'click', 'a.menu-logo, a.menu-logo-text, ul.dropdown-menu > li > a:not([data-remote="true"])', (event) ->
        if $('td input.changed').length > 0
          event.preventDefault()
          event.stopPropagation()

          callback = ->
            window.location.href = event.currentTarget.href

          Utility.ConfirmationDialog.show({ mainText: confirm_form_change }, callback)

        # Nothing changed so continue with original action
        true

      $(document).on 'ajax:beforeSend', 'ul.dropdown-menu > li > a, li.link > a.new-link, a.view-link', (event, xhr, settings) ->
        # Check if the AJAX request is manually triggered after confirmation
        if settings.actionConfirmed
          # Proceed with the AJAX request
          return true

        if $('td input.changed').length > 0
          event.preventDefault()

          callback = ->
            # Set a flag to indicate this request is confirmed & perform the action
            settings.actionConfirmed = true
            $.ajax settings

          Utility.ConfirmationDialog.show({ mainText: confirm_form_change }, callback)

          # Prevent the original AJAX request
          return false

        # Nothing changed so continue with original action
        true

      # Unmount React component when we follow remote links
      $(document).on 'click', 'a[data-remote="true"]', ->
        eventTarget = $(event.target).closest('[data-remote="true"')

        if !eventTarget.is('[data-no-react-unmount="true"]')
          ReactRailsUJS.unmountComponents()

      $(document).on 'submit', 'form[data-remote="true"]', ->
        eventTarget = $(event.target).closest('[data-remote="true"')
        if !eventTarget.is('[data-no-react-unmount="true"]')
          ReactRailsUJS.unmountComponents()

      $(document).on 'click', "a.view-link, a.new-link", ->
        unless $(this).hasClass('show-fly-in')
          $("#main-menu").find("li").removeClass('active');

      $(document).on 'click', "[data-click='back']", ->
        history.back()

      $(document).on 'click', "[data-click='back_2']", ->
        history.go(-2)

      $(document).on 'click', "[data-click='navigate']", ->
        $.getScript($(@).attr('data-url'))

      $(document).on 'click', "[data-click='clear']", ->
        $($(@).attr('data-id')).html('')
        $($(@).attr('data-hide-id')).hide()
        $($(@).attr('data-show-id')).show()
        if $($(@).attr('data-add')).length > 0
          $($(@).attr('data-add')).show()

      $(document).on 'click', "[data-click='redraw-table']", ->
        $('table.dataTable').DataTable().draw()

      $(document).on 'click', "[data-click='clear-local-storage']", ->
        localStorage.clear()
        location.reload(true)

      $(document).on 'click', "[data-click='hide-hint']", ->
        $.get '/hideable_hints/' + $(this).attr('data-hint-id') + '/hide', null, null, 'script'
        return

      $(document).on 'click', "[data-click='show-hide']", ->
        $($(@).attr('data-show-id')).show()
        $($(@).attr('data-hide-id')).hide()
        $(@).addClass('active')
        $(@).parent('li').siblings('li').children('a').removeClass('active')

      $(document).on 'click', "[data-click='hide']", ->
        target = $($(@).attr('data-hide-id'))
        target.hide()

      $(document).on 'click', '.task-tooltip, .task-work-order-tooltip', (event) ->
        event.stopPropagation()
        event.preventDefault()

      $(document).on 'click', 'a.inner-link, i.inner-link', (event) ->
        return if $(@).hasClass('mail-to') || $(@).hasClass('fa collapse')

        event.stopPropagation()
        event.preventDefault()
        unless $(@).attr('data-method') == 'patch' || $(@).attr('data-method') == 'delete'
          $.get $(@).attr('href'), null, null, 'script'

      $(document).on 'ajax:beforeSend', (event, xhr, _settings) ->
        element = event.target
        # Set the fly-in render target header for fly-in links or forms submitted from within the fly-in panel
        if $(element).hasClass('show-fly-in') || $(element).closest('#fly-in-panel').length > 0
          xhr.setRequestHeader('X-Render-Target', 'fly-in')
          # Note location from which panel was launched in order to maintain proper cancel behavior
          unless Utility.FlyInPanel.flyInActive
            $("#fly-in-panel").data("origin-url", window.location.href)

      $(document).on 'click', '.show-fly-in', -> Utility.FlyInPanel.show()
      $(document).on 'click', '.hide-fly-in', -> Utility.FlyInPanel.hide(true, true)
      $(document).on 'click', '.hide-fly-in-with-reload', -> Utility.FlyInPanel.hide()
      $(document).on 'click', '.hide-fly-in-on-submit', -> Utility.FlyInPanel.setShouldClose(true, false)
      $(document).on 'click', '.hide-fly-in-on-submit-with-reload', -> Utility.FlyInPanel.setShouldClose(true, true)
      $(document).on 'click', '.keep-fly-in-on-submit', -> Utility.FlyInPanel.setShouldClose(false)
      $(document).on 'click', '#file-upload-button', -> Utility.FlyInPanel.setShouldClose(false)
      $(document).on 'click', '.hide-fly-in-on-submit-skip-navigation', -> Utility.FlyInPanel.hide(true, false, true)
      $(document).on 'click', '.hide-help', -> Utility.HelpPanel.hide()

      $(document).on 'focus mouseup', 'input.autoselect', (e) ->
        if e.type == 'mouseup'
          e.preventDefault()
        else
          $(@).select()

      $(document).off('mouseenter', '.hovertip').on 'mouseenter', ".hovertip", (e) ->
        if e.target == $(@)[0]
          $.get $(@).data('url'), null, null, 'script'

      $(document).off('change', 'input.submit-on-change, select.submit-on-change').on 'change', 'input.submit-on-change, select.submit-on-change', (e) ->
        $(@).closest('form').submit()

      $(document).off('change', "#task_part_default_inventory_id").on 'change', "#task_part_default_inventory_id", ->
        id = $(@).val()
        task_id = $(@).attr('data-assigned-task-id')

        $.ajax
          url: "/task_part_defaults/get_part_unit_cost?part_id="+id
          data: null
          dataType: 'script'

      $(document).off('change', "#part_kit_inventory_inventory_id").on 'change', "#part_kit_inventory_inventory_id", ->
        id = $(@).val()
        task_id = $(@).attr('data-assigned-task-id')

        $.ajax
          url: "/part_kit_inventories/get_part_unit_cost?part_id="+id
          data: null
          dataType: 'script'

      $(document).off('keyup', '#admin_search').on 'keyup', '#admin_search', (e) ->
        if ($(@).val().trim().length < 2) || (e.keyCode == 27)
          return
        $.get '/admin/organizations/search?t=' + Date.now() +  '&q=' + encodeURIComponent($(@).val().trim()), null, null, 'script'

      $(document).off('keyup', '#global_search').on 'keyup', '#global_search', (e) ->
        if ($(@).val().trim().length < 2) || (e.keyCode == 27)
          return
        $.get '/global_search?t=' + Date.now() + '&q=' + encodeURIComponent($(@).val().trim()), null, null, 'script'

      $(document).off('keydown').on 'keydown', (e) ->
        if e.keyCode == 27
          $('.hide-on-escape').each ->
            $(this).hide()
        else if (e.key == 'k' || e.key == 'K') && (e.ctrlKey || e.metaKey)
          e.preventDefault()
          $('#global_search').focus()

      if $('#top-bar').is(':visible')
        $('#alerts').appendTo('#top-bar .container nav.signed-in')
      else
        $('#alerts').appendTo('.custom-search .controls')

      $(window).scroll ->
        nextLink = $("a.autoscroll[rel='next']")
        if nextLink.length > 0
          el = nextLink[0]
          elTop = el.getBoundingClientRect().top
          elBottom = el.getBoundingClientRect().bottom
          if elTop > 0 && elBottom <= window.innerHeight
            nextLink.removeClass 'autoscroll'
            nextLink.text 'Loading...'
            $.ajax
              url: el.href
              method: 'GET'
              dataType: 'script'

  ###
  Run the new action for the create action.  Generally the create action will 'render :new' if there was a problem.
  This prevents doubling the code for each action.
  ###
  create: ->
    if typeof $this.new == 'function'
      return $this.new()


  ###
  Run the edit action for the update action.  Generally the update action will 'render :edit' if there was a problem.
  This prevents doubling the code for each action.
  ###
  update: ->
    if typeof $this.edit == 'function'
      return $this.edit()


  ###
  Clear event handlers with a blank namespace.  This will prevent window and document event handlers from stacking
  when each new page is fetched.  Adding a namespace to your events will prevent them from being removed between page
  loads, i.e. "$(window).on 'scroll.app', myHandler"
  ###
  setClearEventHandlers: ->
    jQuery(document).on 'page:before-change', ->
      for element in [window, document]
        for event, handlers of (jQuery._data(element, 'events') || {})
          for handler in handlers
            if handler? && handler.namespace == ''
              jQuery(element).off event, handler.handler

window.App ||= {}
class App.TaskTechnicians extends App.Base

  @prepareNewEdit: =>
    App.TaskTechnicians.setTaskTechnicianColspan()

  @setTaskTechnicianColspan: =>
    visibleColumns = $('tr.task-technicians-row tr.dynamic-colspan-row:first td:visible').length
    if visibleColumns == 0
      visibleColumns = $('tr.task-technicians-row tr.dynamic-colspan-row:last td:visible').length
    if visibleColumns == 0
      visibleColumns = 7
    $('tr.task-technicians-row td.dynamic-colspan-cell').attr('colspan', visibleColumns)

window.App ||= {}
class App.InventoryMasters extends App.Base
  new: =>
    Utility.AccordionTab.prepare()
    Utility.FormActions.newEditForm()
    this.prepareEdit()
    this.prepareFuelTank()
    App.CustomFields.showOrHideAddLink()
    App.InventoryMasters.prepareRemoveInventory()
    App.InventoryMasters.toggleRemoveInventory()
    App.InventoryMasters.prepareRemoveSupplier()
    App.InventoryMasters.toggleRemoveSupplier()
    App.InventoryMasters.prepareSubstitute()
    App.InventoryMasters.prepareGlobalSupplier()
    App.InventoryMasters.prepareCheckPartNumber()
    Utility.FlyInPanel.prepare()

  edit: =>
    Utility.AccordionTab.prepare()
    Utility.FormActions.newEditForm()
    this.prepareEdit()
    this.prepareScan()
    this.prepareFuelTank()
    App.CustomFields.showOrHideAddLink()
    App.InventoryMasters.prepareRemoveInventory()
    App.InventoryMasters.toggleRemoveInventory()
    App.InventoryMasters.prepareRemoveSupplier()
    App.InventoryMasters.toggleRemoveSupplier()
    App.InventoryMasters.prepareSubstitute()
    App.InventoryMasters.prepareGlobalSupplier()
    Utility.FlyInPanel.prepare()

  prepareFuelTank: =>
    App.InventoryMasters.toggleFuelTank()
    $("#inventory_master_fuel_tank").change ->
      App.InventoryMasters.toggleFuelTank()

  prepareScan: =>
    $(".scan-key").click ->
      if (typeof window.JsInterface != 'undefined')
        JsInterface.scanInventoryKey($(@).data('id'))
      else
        ScanInterface.scanInventoryKey($(@).data('id'))

  prepareEdit: =>
    App.InventoryMasters.toggleWarehouseInputs()
    $('#inventory_master_tracking_enabled').on 'change', ->
      App.InventoryMasters.toggleWarehouseInputs()

  @toggleFuelTank = ->
    if $("#inventory_master_fuel_tank[type='checkbox']").prop('checked') || ($("#inventory_master_fuel_tank[type='hidden']").val() == 'true')
      $("#inventory_master_fuel_tank[type='checkbox']").prop 'checked', true
      App.InventoryMasters.toggleWarehouseInputs()
      $("#inventory_master_unit_type")[0].selectize.setValue(Utility.RailsVars.default_fuel_volume_units)
      $('label[for="inventory_master_part_number"]').text('Tank ID')
      $('label.maximum-quantity').text('Capacity')
      $('#substitutes-section').hide()
      $('#fuel-type-select').show()
    else
      $('label[for="inventory_master_part_number"]').text('Part Number')
      $('label.maximum-quantity').text('Maximum Quantity')
      $('#substitutes-section').show()
      $('#fuel-type-select').hide()

  @toggleWarehouseInputs = ->
    App.InventoryMasters.toggleUnsavedWarehouseInputs()
    App.InventoryMasters.toggleSavedWarehouseInputs()

  @toggleUnsavedWarehouseInputs = ->
    $('select.warehouse-id').each ->
      App.InventoryMasters.toggleUnsavedWarehouseInput($(@))
    $('select.warehouse-id').off('change').on 'change', ->
      App.InventoryMasters.toggleUnsavedWarehouseInput($(@))

  @toggleUnsavedWarehouseInput = (input) ->
    console.log('value ' + input.val())
    if input.val() > 0 && $('#inventory_master_tracking_enabled').prop('checked')
      input.parent().parent().parent().find('.warehouse-fields input').prop 'disabled', false
      input.parent().parent().parent().find('.warehouse-fields label').removeClass 'disabled'
    else if input.val() > 0
      input.parent().parent().parent().find('.warehouse-fields input').prop 'disabled', false
      input.parent().parent().parent().find('.warehouse-fields label').removeClass 'disabled'
      input.parent().parent().parent().find('.tracking-quantity-fields input').prop 'disabled', true
      input.parent().parent().parent().find('.tracking-quantity-fields label').addClass 'disabled'
    else
      input.parent().parent().parent().find('.warehouse-fields input').prop 'disabled', true
      input.parent().parent().parent().find('.warehouse-fields label').addClass 'disabled'

  @toggleSavedWarehouseInputs = ->
    $('h4.warehouse-title.persisted').each ->
      App.InventoryMasters.toggleSavedWarehouseInput($(@))

  @toggleSavedWarehouseInput = (input) ->
    if $('#inventory_master_tracking_enabled').prop('checked')
      input.parent().find('.warehouse-fields input').prop 'disabled', false
      input.parent().find('.warehouse-fields label').removeClass 'disabled'
    else
      input.parent().find('.warehouse-fields input').prop 'disabled', false
      input.parent().find('.warehouse-fields label').removeClass 'disabled'
      input.parent().find('.tracking-quantity-fields input').prop 'disabled', true
      input.parent().find('.tracking-quantity-fields label').addClass 'disabled'


  @scan = (scanKey) ->
    $.ajax
      url: "/inventories/scan"
      type: 'GET'
      data: {
        scan_key: Utility.Scanner.trimScanKey(scanKey)
      }
      cache: false
      dataType: 'script'

  @scanKey = (id, scanKey) ->
    $.ajax
      url: "/inventories/" + id + "/edit"
      type: 'GET'
      data: {
        scan_key: Utility.Scanner.trimScanKey(scanKey)
      }
      cache: false
      dataType: 'script'

  @prepareCheckPartNumber: =>
    $('#inventory_master_part_number').change ->
      $.ajax
        url: "/inventory_masters/get_existing_part?part_number=" + $(@).val()
        data: null
        dataType: 'script'

  @toggleRemoveInventory: =>
    if $('.nested-fields.inventory-fields:visible').length > 1
      $('.inventory-fields a.remove_fields').show()
    else
      $('.inventory-fields a.remove_fields').hide()

  @prepareRemoveInventory: =>
    $('#inventories').on('cocoon:after-insert', (e, added_task) ->
      Utility.SelectizeFields.prepare()
      App.InventoryMasters.toggleRemoveInventory()
      App.InventoryMasters.toggleWarehouseInputs()
    ).on('cocoon:after-remove', ->
      App.InventoryMasters.toggleRemoveInventory()
    )

  @prepareGlobalSupplier: =>
    $('input.global-supplier-checkbox').each (index, element) =>
      id_array = $(element).attr('id').split('_')
      id = id_array[id_array.length - 3]
      if $(element).is(":checked")
        $('label[for="inventory_master_inventory_suppliers_attributes_' + id + '_warehouse_ids-selectized"]').addClass('disabled')
        $('#inventory_master_inventory_suppliers_attributes_' + id + '_warehouse_ids')[0].selectize.disable()
      else
        $('label[for="inventory_master_inventory_suppliers_attributes_' + id + '_warehouse_ids-selectized"]').removeClass('disabled')
        $('#inventory_master_inventory_suppliers_attributes_' + id + '_warehouse_ids')[0].selectize.enable()

    $('input.global-supplier-checkbox').off('change').on 'change', ->
      id_array = $(@).attr('id').split('_')
      id = id_array[id_array.length - 3]
      if $(@).is(":checked")
        $('label[for="inventory_master_inventory_suppliers_attributes_' + id + '_warehouse_ids-selectized"]').addClass('disabled')
        $('#inventory_master_inventory_suppliers_attributes_' + id + '_warehouse_ids')[0].selectize.disable()
      else
        $('label[for="inventory_master_inventory_suppliers_attributes_' + id + '_warehouse_ids-selectized"]').removeClass('disabled')
        $('#inventory_master_inventory_suppliers_attributes_' + id + '_warehouse_ids')[0].selectize.enable()

  @toggleRemoveSupplier: =>
    if $('.nested-fields.inventory-supplier-fields:visible').length > 1
      $('.inventory-supplier-fields a.remove_fields').show()
    else
      $('.inventory-supplier-fields a.remove_fields').hide()

  @prepareRemoveSupplier: =>
    $('#suppliers').on('cocoon:after-insert', (e, added_task) ->
      Utility.SelectizeFields.prepare()
      App.InventoryMasters.toggleRemoveSupplier()
      App.InventoryMasters.prepareGlobalSupplier()
      checkbox = added_task.find('input.global-supplier-checkbox')
      id_array = checkbox.attr('id').split('_')
      id = id_array[id_array.length - 3]
      added_task.find('label[for=inventory_master_inventory_suppliers_attributes_0_global_supplier]').attr('for', 'inventory_master_inventory_suppliers_attributes_' + id + '_global_supplier')
    ).on('cocoon:after-remove', ->
      App.InventoryMasters.toggleRemoveSupplier()
    )

  @prepareSubstitute: =>
    $('#substitutes').on('cocoon:after-insert', (e, added_task) ->
      Utility.SelectizeFields.prepare()
      App.InventoryMasters.removeCurrentPartFromSubstituteLookup()
    )
    App.InventoryMasters.removeCurrentPartFromSubstituteLookup()

  @removeCurrentPartFromSubstituteLookup: =>
    if $('#substitutes').data('inventory-id') > 0
      $('#substitutes select.selectized').each (index) ->
        @.selectize.removeOption($('#substitutes').data('inventory-id'))

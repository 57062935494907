window.App ||= {}
class App.TaskParts extends App.Base

  @prepareNewEdit: =>
    App.TaskParts.setTaskPartColspan()

    $('a[data-click="add-warranty"]').click (e) ->
      e.preventDefault()
      id = '#warranty-input-group'
      App.TaskParts.hidePopup()
      App.TaskParts.showPopup(id, $(@))

    $('a[data-click="close-warranty"]').click (e) ->
      e.preventDefault()
      App.TaskParts.hidePopup()

    $('a[data-click="create-po"]').click (e) ->
      e.preventDefault();
      id = '#create-po-input-group'
      App.TaskParts.hidePopup()
      App.TaskParts.showPopup(id, $(@))

    $('a[data-click="close-po"]').click (e) ->
      e.preventDefault()
      App.TaskParts.hidePopup()

    $('#task_part_warranty_time_units').selectize
      create: false
      sortField: "text"
      openOnFocus: true

  @hidePopup: =>
    $('.form-popup').addClass('tw-hidden').removeClass('im-popup');

  @showPopup: (id, anchor) ->
    $(id).addClass('im-popup').removeClass('tw-hidden').fadeIn().position({my: 'right center', at: 'left center', of: anchor, collision: 'fit'});

  @setTaskPartColspan: =>
    visibleColumns = $('tr.task-parts-row tr.dynamic-colspan-row:first td:visible').length
    if visibleColumns == 0
      visibleColumns = $('tr.task-parts-row tr.dynamic-colspan-row:last td:visible').length
    if visibleColumns == 0
      visibleColumns = 7
    $('tr.task-parts-row td.dynamic-colspan-cell').attr('colspan', visibleColumns)

